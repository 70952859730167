import Axios from "axios";

export const readJSON = url => {
  return Axios.get(url).then(response => response.data);
};

export const sendSignIn = (email, name, type) => {
  const url = `https://ramrodesigns.com/virtual-exibition/Social_Media_SignIn.php?sm=${type}&ema=${email}&usr=${name}`
  Axios.post(url, {})
    .then(response => response.data);
}