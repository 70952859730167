import React, { useState, useEffect, useRef } from "react";
import "./landing.scss";
import { stringJson, logoPath, bgVideoPath, entryVideoPath } from "./constants";
import { readJSON, sendSignIn } from "./utils";
import { useWindowSize, useFullscreen, useToggle } from "react-use";
import { AtSpinner } from "./AtSpinner";
import ReactTooltip from 'react-tooltip';
const YTPlayer = require("yt-player");

const rcBasePath = "./";
let tourVideoPlayer;
let interStop;
const d = new Date();
const currentYear = d.getFullYear();
let retainIframe = true;

const Landing = () => {
  const [strings, setstrings] = useState();
  const [enter, setenter] = useState(false);
  const [loaded, setloaded] = useState(false);
  const [entryPlayed, setentryPlayed] = useState(false);
  const [videoHeight, setvideoHeight] = useState(0);
  const [loadPercent, setloadPercent] = useState(0);
  const [iframeUrl, setiframeUrl] = useState(null);
  const [showExplorugIframe, setshowExplorugIframe] = useState(false);
  const [landingConfig, setlandingConfig] = useState(null);
  const [showInfo, setshowInfo] = useState(false);
  const [showTutorial, setshowTutorial] = useState(false);
  const [selectedDesigner, setselectedDesigner] = useState(null);
  const [popup, setpopup] = useState(null);
  const [playTour, setplayTour] = useState(false);
  const videoRef = useRef(null);
  const entryvideoRef = useRef(null);
  const iframeref = useRef(null);
  const size = useWindowSize();
  const bodyDiv = useRef(null);
  const [show, toggle] = useToggle(false);
  const isFullscreen = useFullscreen(bodyDiv, show, { onClose: () => toggle(false) });
  const [landed, setlanded] = useState(false);
  const [bgImage, setbgImage] = useState(null);

  let iframeEventAdd = false;

  window.videoRef = videoRef;
  useEffect(() => {
    readJSON(stringJson).then((data) => {
      setstrings(data.strings);
      // console.log(data.strings.heading.split("\n"));
    });

    let bodyDivEl = document.getElementById("bodyDiv");
    if (bodyDivEl) {
      bodyDiv.current = bodyDivEl;
    }
    if (entryvideoRef && entryvideoRef.current) {
      entryvideoRef.current.pause();
    }
    document.addEventListener('keydown', keyDownFunction);
    return () => {
      document.removeEventListener('keydown', keyDownFunction);
      if (iframeref?.current && iframeref.current.contentDocument) {
        iframeref.current.contentDocument.body.removeEventListener('keydown', keyDownFunction);
      }
    }
  }, []);

  useEffect(() => {
    if (!videoRef || !videoRef.current) return;
    if (videoRef.current.offsetHeight !== window.screen.height) {
      setvideoHeight(window.screen.height);
    }
  }, [size, videoRef]);

  useEffect(() => {
    if (videoHeight === 0) return;
    if (videoRef.current.offsetWidth < window.screen.width) {
      setvideoHeight(0);
    }
  }, [videoHeight]);

  useEffect(() => {
    if (landed) {
      setbgImage(window.bgImage);
      playTourVideo();
      let landingDetailsPath = window.landingJson ? window.landingJson : "landing.json";
      readJSON(rcBasePath + landingDetailsPath).then((data) => {
        setlandingConfig(data);
        if (data.strings) {
          setstrings({ ...strings, ...data.strings });
        }
        // console.log(data);
      });
    }
  }, [landed]);

  useEffect(() => {
    if (!document.getElementById("crisp-chatbox")) return;
    if (showInfo) {
      document.getElementById("crisp-chatbox").style.opacity = 0;
    } else {
      document.getElementById("crisp-chatbox").style.opacity = 1;
    }
  }, [showInfo]);

  const keyDownFunction = (event) => {
    if (Number(event.keyCode) === 27) {
      setshowExplorugIframe(false);
    }
  }

  const entryPlayedHandler = () => {
    document.getElementById("root").style.background = "transparent";
    document.getElementById("root").style.pointerEvents = "none";
    document.getElementById("landing-container").classList.add("fadeOut");
    setTimeout(() => {
      // document.getElementById("root").style.background = "transparent";
      document.getElementById("button-overlays").style.display = "block";
      setshowInfo(true);
    }, 1500);
    if (window.setUnityContainerFull) {
      window.setUnityContainerFull();
    }
  };

  const playTourVideo = () => {
    tourVideoPlayer = new YTPlayer("#tourVideo", { related: false, keyboard: false, controls: false, loop: true });
    tourVideoPlayer.mute();
    tourVideoPlayer.setPlaybackQuality("hd1080");
    tourVideoPlayer.setSize(1.78 * (window.screen.height + 5), window.screen.height + 5);
    let videoId = window.youtubeVideoId ? window.youtubeVideoId : "3d36DXB1OWA";
    tourVideoPlayer.load(videoId, [false]);

    let inter = setInterval(() => {
      // console.log(tourVideoPlayer.getState());
      let thisState = tourVideoPlayer.getState();
      if (thisState === "playing" || thisState === "cued") {
        clearInterval(inter);
        document.getElementById("tourVideo").style.display = "block";
        tourVideoPlayer.pause();
        tourVideoPlayer.mute();
        tourVideoPlayer.setPlaybackQuality("hd1080");
        tourVideoPlayer.setSize(1.78 * (window.screen.height + 5), window.screen.height + 5); //fit by height
        // tourVideoPlayer.setSize(window.screen.width + 5, (window.screen.width + 5) / 1.78); //fit by width
        tourVideoPlayer.seek(0);
        tourVideoPlayer.play();
        setplayTour(true);
      }
    }, 2000);
    interStop = setInterval(() => {
      let dur = 0;
      if (tourVideoPlayer && tourVideoPlayer.getDuration) {
        dur = tourVideoPlayer.getDuration();
      }
      if (tourVideoPlayer.getState() === "playing" && dur !== 0) {
        if (tourVideoPlayer.getDuration() - tourVideoPlayer.getCurrentTime() < 3) {
          tourVideoPlayer.seek(0);
          tourVideoPlayer.play();
        }
      } else if (
        tourVideoPlayer.getState() === "unstarted" ||
        tourVideoPlayer.getState() === "ended" ||
        tourVideoPlayer.getState() === "paused"
      ) {
        tourVideoPlayer.seek(0);
        tourVideoPlayer.play();
      }
    }, 1000);
  };
  // window.try = playTourVideo;

  const playVideo = () => {
    if (enter && videoRef && videoRef.current && window.playExteriorVideo) {
      videoRef.current.playbackRate = 0.5;
      videoRef.current.play();
    }
  };

  const playEntryVideo = () => {
    if (entryvideoRef && entryvideoRef.current) {
      setTimeout(() => {
        document.getElementById("entryvideoElem").style.opacity = 1;
        entryvideoRef.current.currentTime = 0;
        entryvideoRef.current.play();
        document.getElementById("tourVideo").classList.add("fadeOut");
        if (tourVideoPlayer) {
          setTimeout(() => {
            tourVideoPlayer.destroy();
            if (interStop) clearInterval(interStop);
          }, 500);
        }
      }, 500);
    }
  };

  const onEnter = (email, name, type) => {
    if (email && name && type) {
      sendSignIn(email, name, type);
    }
    setenter(true);
    if (loaded) {
      if (window.playEntryVideo) {
        playEntryVideo();
        let dur = entryvideoRef.current.duration * 1000;
        setTimeout(() => {
          entryPlayedHandler();
        }, dur - 1000);
      } else {
        entryPlayedHandler();
      }
    }
  };

  window.enter = onEnter;
  window.landed = () => {
    setlanded(true);
  };

  window.unityAssetsLoaded = () => {
    setloaded(true);
    if (enter) {
      if (window.playEntryVideo) {
        playEntryVideo();
        let dur = entryvideoRef.current.duration * 1000;
        setTimeout(() => {
          entryPlayedHandler();
        }, dur - 1000);
      } else {
        entryPlayedHandler();
      }
    }
  };

  window.setProgress = (progress) => {
    setloadPercent(progress);
  };
  function getJsonFromUrl(url) {
    if (!url) url = window.location.search;
    var query = url.substr(1);
    var result = {};
    query.split("&").forEach(function (part) {
      var item = part.split("=");
      result[item[0]] = decodeURIComponent(item[1]);
    });
    return result;
  }

  const sendMessage = function (msg) {
    if (!iframeref?.current || !msg) return;
    if (iframeref.current.contentWindow)
      iframeref.current.contentWindow.postMessage(msg, "*");
  };

  window.openIframe = (url) => {
    if (url.includes("createyourrug.com")) {
      retainIframe = false;
      let urlsplit = url.split("&initdesign=");
      let designctf = urlsplit[1].split("/").reverse()[0];
      let design = designctf.split(".")[0];
      let newurl = urlsplit[0] + "&initdesign=" + design;
      if (iframeUrl !== newurl) {
        setiframeUrl(newurl);
      }
      else {
        handleExplorugIframe(newurl)
      }
    }
    else {
      if (!iframeUrl || !iframeref?.current) {
        if (url.includes("explorug.net")) {
          let urlsplit = url.split("&design=");
          let designctf = urlsplit[1].split("/").reverse()[0];
          let design = designctf.split(".")[0];
          let newurl = urlsplit[0] + "&design=" + design;
          if (iframeUrl !== newurl) {
            setiframeUrl(newurl);
          }
          else {
            handleExplorugIframe(newurl)
          }
        } else {
          if (iframeUrl !== url) {
            setiframeUrl(url);
          }
          else {
            handleExplorugIframe(url)
          }
        }
      }
      else {
        let jsonurl = getJsonFromUrl(url);
        let designpath = jsonurl.initdesign;
        sendMessage({
          triggeredDesign: designpath,
          initDesignVariation: "original",
        });
        setTimeout(() => {
          setshowExplorugIframe(true);
        }, 500);
      }
    }

  };

  const handleExplorugIframe = (url) => {
    if (url) {
      setshowExplorugIframe(true);
    }
    else {
      setshowExplorugIframe(false);
    }

    if (!iframeEventAdd && iframeref?.current) {
      let iframeDoc = iframeref.current.contentDocument || iframeref.current.contentWindow.document;
      if (!iframeDoc) {
        iframeref.current.contentDocument.body.addEventListener('keydown', keyDownFunction);
        iframeEventAdd = true;
      }
    }
  }

  useEffect(() => {
    handleExplorugIframe(iframeUrl);
  }, [iframeUrl]);

  useEffect(() => {
    if (showExplorugIframe) {
      window.unityInstance && window.unityInstance.SendMessage('Player', 'enableIdleRotation', 0);
      window.getFocused = "0";
    }
    else {
      window.unityInstance && window.unityInstance.SendMessage('Player', 'enableIdleRotation', 1);
      window.getFocused = "1";
    }
  }, [showExplorugIframe]);

  useEffect(() => {
    if (!document.getElementById("g-signin2")) return;
    if (selectedDesigner) {
      document.getElementById("g-signin2").classList.add("hide");
    } else {
      document.getElementById("g-signin2").classList.remove("hide");
    }
  }, [selectedDesigner]);
  window.callpopup = (text) => {
    setpopup(text);
  };

  const onEntryVideoPlayed = () => {
    if (loaded && enter) {
      setentryPlayed(true);
    } else {
      entryvideoRef.current.pause();
    }
  };

  const openLink = (url) => {
    if (!url) return;
    window.open(url, "_blank")
  }
  return (
    <React.Fragment>
      <ReactTooltip backgroundColor="#f7f8f8" textColor="#676F75" />
      <div className={(showExplorugIframe ? "" : "hide") + " iframe-overlay"} >
        <div className={"iframe-wrapper"}>
          <AtSpinner />
          {(retainIframe ? true : showExplorugIframe) &&
            <iframe src={iframeUrl} ref={iframeref} height="100%" width="100%" title="explorug iframe" />}
          <button className="btn-close" onClick={() => setshowExplorugIframe(false)}>
            <img src={"./close.svg"}></img>
          </button>
        </div>
      </div>
      {popup && (
        <div className="modal popup">
          <p>{popup}</p>
          <button className="btn-primary" onClick={() => setpopup(null)}>
            Ok
          </button>
        </div>
      )}
      <div className="button-overlays" id="button-overlays">
        <img
          src="./info-circle.svg"
          className={(!showInfo ? "show " : "") + "info"}
          data-tip="Info"
          data-place="left"
          onClick={() => {
            setshowInfo(true);
          }}
        ></img>

        <div
          className={(showInfo ? "show " : "") + "modal info-modal"}
          style={{ maxWidth: "360px", textAlign: "left" }}
        >
          {strings && (
            <React.Fragment>
              <p>{strings.infoText1}</p>
              <p>{strings.infoText2}</p>
              <p>{strings.infoText3}</p>
              {strings.infoText4.length > 0 &&
                strings.infoText4.map((item, index) => {
                  return (
                    <p className={"last" + (index === 0 ? " nospace" : "")} key={index}>
                      {item}
                    </p>
                  );
                })}
              <h3 onClick={() => window.open(strings.onlineGuide.link)}>{strings.onlineGuide.text}</h3>
              <p className="last">
                The designs shown here are copyright of the respective owners. Copying is strictly prohibited.
              </p>
              <p className="last" style={{ marginBottom: "1rem" }}>
                The customizations made to the design are only for preview and will not be retained in your purchased
                design files.
              </p>
              <p className="last">VirtualRugExhibition.com ©2010-{currentYear}</p>
              <p
                className="last"
                style={{ textDecoration: "underline", cursor: "pointer", pointerEvents: "all" }}
                onClick={() => window.open(strings.copyright.link)}
              >
                Alternative Technology
              </p>
            </React.Fragment>
          )}
          <button
            className="btn-primary"
            onClick={() => setshowInfo(false)}
            style={{ float: "right", marginTop: "1rem" }}
          >
            Close
          </button>
        </div>
        {showTutorial && strings && strings.tutorialText && (
          <div className="modal tutorial-modal">
            {strings.tutorialText.length > 0 &&
              strings.tutorialText.map((item, index) => {
                return <p key={index}>{item}</p>;
              })}
            <button className="btn-primary" onClick={() => setshowTutorial(false)}>
              Ok
            </button>
          </div>
        )}
        {!isFullscreen ? (
          <img src="./enter-fullscreen.svg" className="fullscreen" data-tip="Fullscreen" data-place="bottom" onClick={toggle}></img>
        ) : (
          <img src="./exit-fullscreen.svg" className="fullscreen" data-tip="Exit Fullscreen" data-place="bottom" onClick={toggle}></img>
        )}
        <img
          src="./reset.svg"
          className={"reset"}
          data-tip="Reset"
          data-place="bottom"
          onClick={() => {
            window.unityInstance && window.unityInstance.SendMessage('Player', 'resetPlayer');
          }}
        ></img>
      </div>
      <div id="landing-container" style={{ background: `url(${bgImage})` }} >
        {enter && window.playEntryVideo && (
          <video
            id="entryvideoElem"
            style={{
              height: videoHeight !== 0 ? `${videoHeight + 5}px` : `auto`,
              width: videoHeight !== 0 ? `auto` : `100%`,
              opacity: 0,
            }}
            ref={entryvideoRef}
            onEnded={onEntryVideoPlayed}
            playsInline
            muted
            preload="auto"
          >
            <source src={`${entryVideoPath}.webm`} type="video/webm" />
            <source src={`${entryVideoPath}.mp4`} type="video/mp4" />
          </video>
        )}

        {selectedDesigner && landingConfig.profiles[selectedDesigner] && (
          <div className="designer-profile">
            <button className="btn-close" onClick={() => setselectedDesigner(null)}>
              <img src={"./close.svg"}></img>
            </button>
            <div className="designer-image-wrapper">
              <img className="designer-image" src={landingConfig.profiles[selectedDesigner].image}></img>
            </div>
            <h4>Designs by</h4>
            <h1 className="designer-name">{selectedDesigner}</h1>
            <div className="designer-description">
              {landingConfig.profiles[selectedDesigner].bio &&
                landingConfig.profiles[selectedDesigner].bio.map((data, index) => {
                  return data.linespace < 0 ? (
                    <span
                      className={data.type === "link" ? "link" : ""}
                      onClick={() => {
                        if (data.type === "link") window.open(data.text);
                      }}
                    >
                      {data.text}
                    </span>
                  ) : (
                    <p
                      key={index}
                      style={{
                        fontStyle: data.style,
                        marginBottom: data.linespace === 0 ? 0 : data.linespace + "rem",
                        marginTop: data.linespace === 0 ? 0 : "1rem",
                        fontWeight: data.weight ? data.weight : "normal",
                      }}
                    >
                      {data.text}
                    </p>
                  );
                })}
            </div>
          </div>
        )}
        <div className={(enter && loaded ? "enter " : "") + "landingInfoOverflowWrap"}>
          <div className={"landingInfoContainer"} onClick={playVideo}>
            <div className="placeholder topArea"></div>
            <div className="middleArea">
              <img className="vex-logo" src={landingConfig?.logoUrl || "./logo.png"}
                onClick={() => window.open(strings.vexWebsite)}
              ></img>
              {/* <div>
              {strings && strings.heading.split("\n").map((eachpart, index) => <h1 key={index}>{eachpart}</h1>)}
            </div>
            <h4>{strings && strings.subheading}</h4> */}
              {landingConfig && (
                <div className="config-details">
                  {landingConfig.exhibitionName && <h3 style={{ cursor: landingConfig.featureUrl ? "pointer" : "default" }}
                    className={landingConfig.featureUrl ? "link" : ""}
                    onClick={() => openLink(landingConfig.featureUrl)}
                  >{landingConfig.exhibitionName}</h3>}
                  {landingConfig.exhibitionDate && <h5>{landingConfig.exhibitionDate}</h5>}
                  {landingConfig.exhibitionDescriptionHeading && <h5 className="exhibition-desc-head">{landingConfig.exhibitionDescriptionHeading}</h5>}
                  {landingConfig.exhibitionDescription?.length &&
                    landingConfig.exhibitionDescription.map((el) => <h5 className={el.class || ''}>{el.text}</h5>)
                  }
                  <br />
                  {landingConfig.exhibitionDesigners && landingConfig.exhibitionDesigners.length > 0 && (
                    <React.Fragment>
                      <div>Featuring designs by :</div>
                      {landingConfig.exhibitionDesigners.map((item, index) => (
                        <h3 className="designer-name-outer" key={index} onClick={() => setselectedDesigner(item)}>
                          {item}
                        </h3>
                      ))}
                    </React.Fragment>
                  )}
                </div>
              )}

              {strings && (
                <button id="btn-enter" onClick={onEnter} className={enter ? "show" : "hide"}>
                  {" "}
                  {!enter ? strings.guestBtn : "Loading..."}
                  {enter && loadPercent > 0 && <span className="loading-percent">{loadPercent}%</span>}
                </button>
              )}
              <button className={"btn-secondary"} onClick={() => window.open(strings.onlineGuide.link)}>
                Read online guide
              </button>
            </div>
            <div className="bottomArea">
              {landingConfig?.logoUrl && <img
                src="./logo-horiz.png"
                className={"logoArea vex-logo"}
                alt="VEX logo"
                onClick={() => window.open(strings.vexWebsite)}
              ></img>}
              <div>
                <div className={"bp3-text-small"}>{strings && strings.footerText}</div>
                <img
                  src={logoPath}
                  className={"logoArea"}
                  alt="exploRUG logo"
                  onClick={() => window.open(strings.explorugWebsite)}
                ></img>
              </div>

            </div>
          </div>
        </div>
        <div id="tourVideoWrapper" className="ytplayerwrapper">
          <div id="tourVideo" className="ytplayer"></div>
        </div>
        {enter && window.playExteriorVideo && (
          <video
            id="bgVideo"
            className={"bgVideo " + ((enter && loaded) || playTour ? "enter " : "")}
            style={{
              height: videoHeight !== 0 ? `${videoHeight + 5}px` : `auto`,
              width: videoHeight !== 0 ? `auto` : `100%`,
            }}
            ref={videoRef}
            autoPlay
            loop
            playsInline
            muted
          >
            <source src={`${bgVideoPath}.webm`} type="video/webm" />
            <source src={`${bgVideoPath}.mp4`} type="video/mp4" />
          </video>
        )}
      </div>
    </React.Fragment>
  );
};

export default Landing;
